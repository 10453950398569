import React from 'react';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';

const Error404Page: React.FC = () => (
  <Layout>
    <SEO title="404" />
    <div className="container mx-auto text-center">
      <div className="py-8">
        <h1 className="text-4xl">Oops!</h1>
        <h2 className="text-3xl font-semibold">
          We could not find the page you requested
        </h2>
      </div>
    </div>
  </Layout>
);

export default Error404Page;
